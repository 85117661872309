.tickets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.add_ticket {
  color: var(--gray-200);
  background-color: var(--gray-600);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

@media (hover: hover) and (pointer: fine) {
  .add_ticket:hover {
    background-color: var(--gray-400);
  }
}

.add {
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
}

.add span {
  color: var(--gray-200);
  font-size: 1.125rem;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info svg {
  width: 15px;
  height: 15px;
  outline: none;
  fill: var(--primary-color-300);
}

.info svg path {
  fill: inherit;
}

.fluid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.templates {
  display: flex;
  gap: 50px;
  margin-top: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
}

.templates_wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
}

.templates_wrapper_titles {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: flex-start;
  align-items: flex-start;
}
