.actual {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%233AA7D6FF' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.actual_shadow {
  position: absolute;
  top: 0px;
  left: 5px;
  height: 2px;
  background-color: #fff;
  width: 67px;
  z-index: 1;
}

.actual_text {
  position: absolute;
  top: -10px;
  left: 13px;
  height: fit-content;
  background-color: transparent;
  padding: 0 5px;
  color: var(--primary-color-100);
  z-index: 2;
}

.template {
  padding: 25px 35px;
  box-shadow: 0px 0px 40px -15px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.template_top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.template_top svg {
  width: 22px;
  height: 22px;
  fill: var(--primary-color-100);
}

.template_top span {
  font-size: 1.125rem;
}

.des {
  font-size: 1rem !important;
  margin-bottom: 10px;
  font-style: italic;
  color: var(--gray-200);
}

.field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.field_type {
  padding: 1px 10px;
  background-color: var(--gray-100);
  border-radius: 5px;
  width: fit-content;
}
