.title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.title_name {
  font-size: 2rem;
}

.title_input {
  font-size: 2rem;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--gray-400);
  padding-left: 10px;
}

.title_icon {
  cursor: pointer;
}

.title_icon svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-color-100);
}

.cancel {
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 5px;
  background-color: var(--gray-400);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel svg {
  fill: var(--gray-200);
}

@media (hover: hover) and (pointer: fine) {
  .title_icon svg:hover {
    fill: var(--primary-color-200);
  }
  .cancel:hover {
    background-color: var(--gray-500);
  }
  .cancel:hover svg {
    fill: var(--gray-200);
  }
}
