.container {
  width: 300px;
}

.title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.input_label {
  color: var(--gray-200);
}

.btn {
  background-color: var(--primary-color-100) !important;
  color: #fff !important;
}

.btn:disabled {
  background-color: var(--primary-color-300) !important;
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    background-color: var(--primary-color-300) !important;
    color: #fff !important;
  }
  .btn:disabled:hover {
    background-color: var(--primary-color-200) !important;
    color: #fff !important;
  }
}
