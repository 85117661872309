.skeleton {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.skeleton_chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.skeleton_chat::after {
  content: '';
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: anima_skeleton 1500ms linear infinite normal forwards;
}

.skeleton_chat_img {
  width: 50px;
  height: 50px;
  background-color: var(--gray-400);
  border-radius: 50%;
}

.skeleton_chat_info {
  width: calc(100% - 50px);
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.skeleton_chat_info div {
  border-radius: 10px;
  background-color: var(--gray-400);
}

.skeleton_chat_info_name {
  height: 20px;
  width: 100%;
}

.skeleton_chat_info_sub {
  height: 15px;
  width: 70%;
}

@keyframes anima_skeleton {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}
