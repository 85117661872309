.form_right_notes_title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.form_right_notes_title_text {
  font-size: 1.1rem;
}

.form_right_notes {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form_right_notes_body {
  max-height: 275px;
  overflow-y: auto;
  margin-top: 5px;
}

.notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.note {
  border-left: 5px solid var(--primary-color-200);
  padding-left: 10px;
}

.form_right_notes_title_btn {
  background-color: var(--gray-600);
  padding: 0 20px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.form_right_notes_title_btn {
  height: 30px;
}

@media (hover: hover) and (pointer: fine) {
  .form_right_notes_title_btn:hover {
    background-color: var(--gray-500);
  }
  .note:hover {
    background-color: var(--gray-700);
  }
}
