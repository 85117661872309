.notes_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 0 5px;
  padding-bottom: 10px;
}

.note {
  border-left: 5px solid var(--primary-color-200);
  padding-left: 10px;
}

@media (hover: hover) and (pointer: fine) {
  .note:hover {
    background-color: var(--gray-700);
  }
}
