/* Nav for aside - section pages */
.nav_aside {
  width: 218px;
  padding: 0 30px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-100);
}

.nav_aside_title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #fff;
}

.nav_section {
  width: calc(100vw - 218px);
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  height: 65px;
}

.nav_section a,
.nav_section div {
  text-decoration: none;
  outline: none;
  background-color: var(--gray-500);
  padding: 5px 15px;
  border-radius: 10000px;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .nav_section a:hover,
  .nav_section div:hover {
    background-color: var(--gray-300);
  }
}
