.spin {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Layout for aside - section pages */
.layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.aside {
  width: 218px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: var(--primary-color-100);
  padding: 25px;
  padding-top: calc(65px + 25px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.section {
  width: calc(100vw - 218px);
  background-color: #fff;
  padding: 25px;
  margin-left: 218px;
}

.section_title {
  font-size: 2.25rem;
  color: var(--primary-color-100);
  font-weight: bold;
  width: fit-content;
  margin: 0;
  margin-bottom: 45px;
}

.section_title_wrapper {
  display: flex;
  font-size: 2.25rem;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.section_title_wrapper img {
  height: 100%;
  width: 100%;
}

.span_click {
  cursor: pointer;
}
.span_click:hover {
  text-decoration: underline;
}

.section_title_wrapper span {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_subtitle {
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  margin-top: -5px;
}

.section_subtitle svg {
  width: 15px;
  height: 15px;
  fill: var(--primary-color-100);
}

.section_subtitle span {
  color: var(--gray-200);
  font-size: 1.125rem;
  font-weight: normal;
}
