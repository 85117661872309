.tag {
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
  justify-content: center;
  position: relative;
  align-items: center;
  overflow: hidden;
}

.click {
  cursor: pointer;
}

.selected {
  z-index: 0;
}

.loading {
  cursor: not-allowed !important;
}

.loading::after {
  content: '';
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: anima_skeleton 1500ms linear infinite normal forwards;
}

.no_color::after {
  background: linear-gradient(
    to bottom,
    rgba(150, 150, 150, 0.1),
    rgba(150, 150, 150, 0.2),
    rgba(150, 150, 150, 0.3),
    rgba(150, 150, 150, 0.4),
    rgba(150, 150, 150, 0.3),
    rgba(150, 150, 150, 0.2),
    rgba(150, 150, 150, 0.1)
  ) !important;
}

@keyframes anima_skeleton {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}
