.info {
  height: 82px;
  background-color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.info_data {
  margin-left: 15px;
  width: calc(100% - 100px);
}
.info_telephone {
  color: var(--gray-200);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info_name {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
