.aside {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.aside_company_name {
  display: block;
  text-align: center;
  word-break: break-word;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.aside_links {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.link {
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;
  background-color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .link:hover {
    background-color: var(--primary-color-600);
  }
}

.link_selected {
  background-color: var(--primary-color-500);
}

.link_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link_icon svg {
  width: 15px;
  height: 15px;
  fill: var(--primary-color-100);
}
