.agents {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.agents_info {
  width: 50px;
}

.agents_info svg {
  width: 15px;
  height: 15px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  fill: var(--primary-color-300);
}

.agents_info svg path {
  fill: inherit;
}

.divide {
  width: 40px;
  min-height: 300px;
  height: 100%;
  position: relative;
}

.divide::after {
  content: '';
  position: absolute;
  top: 0;
  left: calc(50% - 0.5px);
  width: 1px;
  background-color: var(--gray-600);
  height: 100%;
}

.agents_container {
  width: calc(100% - 75px);
  display: grid;
  grid-template-columns: 1fr 40px 1fr 40px 1fr;
  height: fit-content;
}
