.user_profile {
  width: 50px;
  height: 50px;
  position: relative;
}

.user_profile_img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--gray-600);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--primary-color-100);
}

.user_profile_unread {
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: var(--danger-100);
  color: #fff;
  height: 18px;
  min-width: 25px;
  max-width: 35px;
  display: block;
  font-size: 0.8rem;
  padding: 0 5px;
  text-align: center;
  border-radius: 1000px;
}

.user_profile_gateway {
  position: absolute;
  right: -5px;
  bottom: -5px;
  background-color: #fff;
  padding: 3px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.user_profile_gateway img {
  width: 100%;
  height: 100%;
}
