.nav {
  height: 60px;
  border-bottom: 1px solid var(--gray-100);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
}

.dropdown {
  border-left: 1px solid var(--gray-100);
  border-right: 1px solid var(--gray-100);
  width: 155px;
  height: 60px;
}

.dropdown select {
  border-radius: 0;
  width: 155px;
  height: 60px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.skeleton_dropdown_container {
  width: 155px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton_dropdown {
  background-color: var(--gray-600);
  width: 120px;
  height: 20px;
  border-radius: 10px;
}

.company {
  width: 310px;
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border-right: 1px solid var(--gray-100);
}

.search {
  width: calc(100% - 310px - 155px - 155px);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.search input {
  height: 60px;
  width: 100%;
  border: none;
  border-radius: 0;
}

.search input:focus {
  outline: none;
}

.search svg {
  width: 20px;
}

.search_input_group {
  height: 60px !important;
}

.skeleton_company {
  background-color: var(--gray-600);
  width: 120px;
  height: 20px;
  border-radius: 10px;
}

.panel {
  width: 155px;
  border-left: 1px solid var(--gray-100);
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_name {
  padding: 0 7.5px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.panel a {
  text-decoration: none;
  outline: none;
  background-color: var(--gray-500);
  padding: 5px 10px;
  border-radius: 10000px;
}

@media (hover: hover) and (pointer: fine) {
  .panel a:hover {
    background-color: var(--gray-300);
  }
}
