.profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--gray-600);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--primary-color-100);
}
