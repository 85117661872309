.skeleton {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.skeleton_client {
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.skeleton_client::after,
.skeleton_profile_user::after {
  content: '';
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: anima_skeleton 1500ms linear infinite normal forwards;
}

.skeleton_profile_user {
  width: 50px;
  height: 50px;
  background-color: var(--gray-400);
  border-radius: 50%;
}

.skeleton_client div {
  height: 20px;
  background-color: var(--gray-400);
  border-radius: 10px;
}

.skeleton_client_bottom {
  width: 100px;
}

.skeleton_client_top {
  width: 150px;
}

@keyframes anima_skeleton {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}
