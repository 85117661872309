.container {
  width: 500px;
}

.title {
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 30px;
}

.btn {
  width: 146px;
  height: 40px;
}

.btn_color {
  background-color: var(--primary-color-100) !important;
  color: #fff !important;
}

.btn_color:disabled {
  background-color: var(--primary-color-300) !important;
}

@media (hover: hover) and (pointer: fine) {
  .btn_color:hover {
    background-color: var(--primary-color-300) !important;
    color: #fff !important;
  }

  .btn_color:disabled:hover {
    background-color: var(--primary-color-200) !important;
    color: #fff !important;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.top_color {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.top_color input[type='color'] {
  background-color: transparent;
  cursor: pointer;
}

.top_name {
  width: 100%;
  margin-bottom: 30px;
}

.prev {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 40px;
}

.prev_tag {
  width: fit-content;
}

.btns {
  display: flex;
  justify-content: flex-end;
  gap: 14px;
}
