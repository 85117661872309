.notes {
  width: 310px;
  height: 100%;
  background-color: #fff;
  padding-bottom: 10px;
}

.notes_gray {
  background-color: var(--gray-700);
}

.notes_header {
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  padding: 20px;
  font-weight: bold;
}

.notes_header_info {
  width: 53px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.notes_header_info svg {
  outline: none;
  width: 80%;
  cursor: pointer;
  fill: var(--primary-color-300);
}

.notes_header_info svg path {
  fill: inherit;
}

.notes_container {
  width: 100%;
  height: calc(100% - 53px - 10px);
  background-color: #fff;
  padding: 0 10px;
  overflow-y: auto;
  padding-bottom: 30px;
}

.tickets {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 0 5px;
}
