.ticket {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 5px 15px;
  cursor: pointer;
  border-left: 5px solid var(--primary-color-200);
  position: relative;
}

.ticket_left {
  display: flex;
  flex-direction: column;
}

.ticket_date {
  color: var(--gray-200);
  font-size: 0.9rem;
  margin-top: 5px;
}

.ticket_number {
  font-weight: bold;
  color: var(--gray-800);
  font-size: 1.1rem;
}

.ticket_wait {
  cursor: progress;
}

.ticket_temp {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.ticket_temp_svg {
  width: 15px;
  height: 15px;
  border-top-color: #fff;
  border-right-color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .ticket:hover {
    background-color: var(--gray-300);
  }
}
