.h1 {
  font-size: 2rem;
  text-align: center;
  margin: 0 0 20px 0;
  font-weight: bold;
}

.container {
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: 5px;
  box-shadow: 0px 0px 50px -30px rgba(0, 0, 0, 0.25);
  padding: 40px 20px 20px 20px;
  border-top: 5px solid var(--primary-color-100);
  width: 280px;
  margin-bottom: 65px;
}

.input_container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.msg {
  color: var(--danger-100);
  margin-top: 10px;
  text-align: center;
}
