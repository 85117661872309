.msgs {
  flex: 2;
  padding: 50px 20px;

  display: flex;
  flex-direction: column-reverse;
  gap: 30px;

  overflow-y: auto;
}
.unread_count {
  margin: 0 auto;
  padding: 2.5px 15px;
  border-radius: 1000px;
  background-color: var(--primary-color-200);
}
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader_more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
