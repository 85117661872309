.body {
  width: calc(100% - 620px);
  height: 100%;
  background-color: var(--gray-700);
  display: flex;
  flex-flow: column;
}
.no_chat {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
