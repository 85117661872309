.chat {
  width: 310px;
  height: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.chat_skeleton {
  padding: 0 15px 15px 15px;
}
