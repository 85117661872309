.form_right_tag {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form_right_tag_title {
  font-size: 1.1rem;
}
.form_right_tag_body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}
.form_right_tag_body_plus {
  background-color: var(--gray-600);
  padding: 0 20px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .form_right_tag_body_plus:hover {
    background-color: var(--gray-500);
  }
}
