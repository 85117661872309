.nav {
  width: 100vw;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
