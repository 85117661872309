.msgs {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.msg {
  padding: 7.5px 10px;
  border-radius: 5px;
  width: 270px;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  overflow: hidden;
}

.msg_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.msg_top_text {
  width: calc(270px - 45px);
}

.msg_top_icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg_top_icon svg {
  width: 70%;
  height: 70%;
}

.msg_async {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  height: 30px;
}

.msg_async button {
  width: 50%;
}

.counter::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  left: 0;
  animation-name: anima_counter;
  animation-duration: 5000ms; /* MILISECONDS_MSGS */
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@media (hover: hover) and (pointer: fine) {
  .msg_async_ok:hover {
    background-color: var(--gray-700);
  }

  .msg_async_cancel:hover {
    background-color: var(--primary-color-200);
  }
}

.msg_async_ok {
  color: #000000;
  background-color: #ffffff;
}

.msg_async_cancel {
  color: #000000;
  background-color: var(--primary-color-400);
}

.danger {
  color: var(--danger-300);
  background-color: var(--danger-200);
}

.danger::after {
  background-color: var(--danger-300);
}

.ask {
  color: #ffffff;
  background-color: var(--primary-color-100);
}

.success {
  color: var(--success-100);
  background-color: var(--success-200);
}

.success::after {
  background-color: var(--success-100);
}

@keyframes anima_counter {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}
