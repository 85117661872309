* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

:root {
  --primary-color-100: #3aa7d6;
  --primary-color-200: #7fcdee;
  --primary-color-300: #60b4d8;
  --primary-color-400: #9cdcf8;
  --primary-color-500: #b6e9ff;
  --primary-color-600: #cef0ff;

  --danger-100: #ef4444;
  --danger-200: #e9adad;
  --danger-300: #d12828;
  --danger-400: #ff6347;

  --success-100: #26572a;
  --success-200: #a6d6aa;

  --gray-100: #c2cbd8;
  --gray-200: #6c727f;
  --gray-300: #f8f8f8;
  --gray-400: #e7e6e6;
  --gray-500: #f3f3f3;
  --gray-600: #eeeeee;
  --gray-700: #f8fafc;
  --gray-800: #b1b1b1;
  --gray-900: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0aec0;
}

::-webkit-scrollbar-thumb:active {
  background-color: #748094;
}

::-webkit-scrollbar-track-piece {
  background-color: #f8fafc;
}

html {
  min-height: 100%;
  width: 100vw;
  position: relative;
}

body {
  position: relative;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1000px) {
  #root {
    display: none;
  }

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  body::after {
    content: 'Esta aplicación no está hecha para dispositivos móviles.';
    text-align: center;
    padding: 0 30px;
  }
}
