.skeleton {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  padding: 7.5px 0;
  position: relative;
}

.skeleton_bottom {
  overflow: hidden;
  width: 220px;
  height: 30px;
  background-color: var(--gray-400);
  border-radius: 10px;
  position: relative;
}

.skeleton_bottom::after {
  content: '';
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: anima_skeleton 1500ms linear infinite normal forwards;
}

@keyframes anima_skeleton {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}
