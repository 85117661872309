.msg {
  width: fit-content;
  max-width: 500px;
  padding: 5px 15px;
  border-radius: 5px;
  word-break: break-word;
  height: auto;
  position: relative;
}

.msg:first-child {
  margin-bottom: auto;
}

.msg_mine {
  margin-left: auto;
  background-color: var(--primary-color-100);
  color: #fff;
}

.msg_client {
  margin-right: auto;
  background-color: var(--gray-400);
}

.msg_agent {
  text-align: right;
  font-weight: bold;
}

.msg_below {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
}

.msg_read {
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg_read svg {
  width: 10px;
  height: 10px;
}

.msg_date {
  text-align: right;
  font-size: 0.8rem;
}

.msg_mine .msg_date {
  margin-left: auto;
}

.msg_client .msg_date {
  margin-right: auto;
}

.msg_temp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
}

.msg_temp::after {
  content: '';
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: anima_skeleton 1500ms linear infinite normal forwards;
}

@keyframes anima_skeleton {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}
