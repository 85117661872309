.info {
  height: 45px;
  width: 100%;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-400);
}

.info_value {
  padding: 0 15px;
  border-radius: 1000px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  border: none;
  outline: none;
}

.btn_bool {
  background-color: var(--gray-400);
  height: 28px;
  width: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_bool div {
  width: 15px;
  height: 15px;
}

@media (hover: hover) and (pointer: fine) {
  .btn_bool:hover {
    background-color: var(--gray-500);
  }
}

.info_value_input {
  border-radius: 0;
}

.info_value_input_loading {
  animation-name: anima_input;
  animation-timing-function: linear;
  animation-duration: 500ms;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.info_value_click {
  min-width: 142px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 16px !important;
  height: 16px !important;
}

.info_value_click_hover {
  cursor: pointer;
}

.info_value_click_hover:hover {
  background-color: var(--gray-400);
}

@keyframes anima_input {
  0% {
    border-bottom: 1px solid var(--gray-800);
  }

  100% {
    border-bottom: 1px solid #fff;
  }
}
