.user {
  width: 100%;
  height: 66px;
  border-bottom: 1px solid var(--gray-100);
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
}

.user::before,
.user::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 5px;
  background-color: #fff;
  bottom: -1px;
  z-index: 1;
}

.user::before {
  left: 0;
}

.user::after {
  right: 0;
}

.user_msg {
  width: calc(100% - 50px);
  height: 50px;
  padding: 0 10px;
}

.user_msg_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_msg_info_name {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user_msg_info_date {
  color: var(--gray-200);
  font-size: 0.8rem;
}

.user_msg_text {
  color: var(--gray-200);
  font-size: 0.8rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected {
  background-color: var(--gray-500);
}

@media (hover: hover) and (pointer: fine) {
  .user:hover {
    background-color: var(--gray-300);
  }

  .selected:hover {
    background-color: var(--gray-500);
  }
}
