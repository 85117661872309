.ticket {
  width: 850px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  font-size: 2rem;
  width: 100%;
}
.form_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form_actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.form_right {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
