.user {
  position: relative;
  width: fit-content;
}

.loading::after {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.075);
  position: absolute;
  font-size: 2rem;
  z-index: 100;
  border-radius: 5px;
  cursor: progress;
}

.user_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .user_wrap:hover {
    background-color: var(--gray-300);
  }
}

.user_wrap div[extra-css='profile-cpanel-agent'] {
  width: 38px;
  height: 38px;
}

.user_name {
  color: var(--gray-200);
  font-size: 1.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_svg svg {
  width: 12.5px;
  height: 12.5px;
  fill: var(--gray-200);
}

.dropdown {
  --size-triangle: 20px;
  position: absolute;
  top: 50px;
  right: -9px;
  background-color: #fff;
  z-index: 1;
  height: fit-content;
  width: fit-content;
  min-width: 100px;
  min-height: 30px;
  border: 1px solid var(--gray-100);
  border-radius: 5px;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
}

.dropdown_active {
  display: flex;
}

.dropdown::before,
.dropdown::after {
  content: '';
  position: absolute;
  right: 15px;
  width: 0;
  height: 0;
  border-left: calc(var(--size-triangle) / 2) solid transparent;
  border-top: calc(var(--size-triangle) / 2) solid transparent;
  border-right: calc(var(--size-triangle) / 2) solid transparent;
  background-color: transparent;
}

.dropdown::before {
  top: calc(-1 * var(--size-triangle));
  border-bottom: calc(var(--size-triangle) / 2) solid var(--gray-100);
}

.dropdown::after {
  top: calc(-1 * var(--size-triangle) + 2px);
  border-bottom: calc(var(--size-triangle) / 2) solid #fff;
}

.option {
  background-color: #fff;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  white-space: nowrap;
}

@media (hover: hover) and (pointer: fine) {
  .option:hover {
    background-color: var(--gray-300);
  }
}

.option svg {
  width: 15px;
  height: 15px;
  fill: var(--primary-color-300);
}

.option span {
  color: var(--gray-200);
}
