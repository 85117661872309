.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.container_top {
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.container_top button {
  color: var(--gray-200);
  background-color: var(--gray-600);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

.container_top svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-color-300);
}

.container_top span {
  color: var(--gray-200);
  font-size: 1.125rem;
}

@media (hover: hover) and (pointer: fine) {
  .container_top button:hover {
    background-color: var(--gray-400);
  }
}

.container_users {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
