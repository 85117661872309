.note {
  display: flex;
  flex-direction: column;
}

.note_instructions {
  margin: 15px 0;
}

.note_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.note_body_text {
  width: 100%;
  height: 250px;
  border: 2px solid var(--gray-100);
  resize: none;
  padding: 10px;
}

.msg {
  color: var(--danger-100);
}
