.company {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -20px;
}

.company_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company_top_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: fit-content;
  cursor: pointer;
}

.company_top_left span {
  color: var(--primary-color-100);
}

.company_top_left svg {
  fill: var(--primary-color-100);
  width: 15px;
  height: 15px;
  outline: none;
}

.company_top_left svg path {
  fill: inherit;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.name span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.name span svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-color-100);
}

.allowed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin-top: 20px;
}

.allowed input {
  width: 234px;
}

.agents_save {
  width: 234px;
  margin-top: 10px;
}

.agents_save_not {
  visibility: hidden;
  opacity: 0;
}

.active {
  margin-top: 75px;
}

.active_title {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.block {
  position: relative;
}

.block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: default;
  z-index: 100;
}

.btn,
.btn_blue {
  padding: 0 30px;
}

.btn {
  background-color: var(--danger-100) !important;
  color: #fff !important;
}

.btn:disabled {
  background-color: var(--danger-100) !important;
}

.btn_blue {
  background-color: var(--primary-color-100) !important;
  color: #fff !important;
}

.btn_blue:disabled {
  background-color: var(--primary-color-300) !important;
}

.edit_name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.edit_name_top input {
  width: 420px;
}
.edit_name_btns {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
.edit_name_btns button {
  width: 200px;
}

@media (hover: hover) and (pointer: fine) {
  .company_top_left:hover {
    text-decoration: underline;
  }

  .btn:hover {
    background-color: var(--danger-300) !important;
    color: #fff !important;
  }

  .btn:disabled:hover {
    background-color: var(--danger-200) !important;
    color: #fff !important;
  }

  .btn_blue:hover {
    background-color: var(--primary-color-300) !important;
    color: #fff !important;
  }

  .btn_blue:disabled:hover {
    background-color: var(--primary-color-200) !important;
    color: #fff !important;
  }

  .name span:hover svg {
    fill: var(--primary-color-200);
  }
}
