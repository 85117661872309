.chat {
  height: 40px;
  min-height: 40px;
  max-height: 150px;
  padding: 10px 0;
  box-sizing: content-box;

  background-color: var(--gray-600);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.chat textarea {
  display: inline;
  resize: none;
  margin: 0;
  border: none;
  padding: 10px;
  height: 100%;
  line-height: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.chat_file {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.chat_file svg {
  width: 20px;
  height: 20px;
}
