.form_inputs {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form {
  display: flex;
  flex-direction: column;
}

.form_switch {
  display: flex;
  justify-content: flex-start;
}
.loading {
  gap: 5px !important;
}
.form label {
  margin-right: auto;
  margin-bottom: 0;
}
.form_switch label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.my_own_input {
  background-color: #fff;
  height: 40px;
  border-radius: 7px;
  border: solid 1px #dddddd;
  outline-width: 1.5px;
  outline-style: solid;
  outline-color: transparent;
  transition: outline-color linear 150ms;
  padding: 0 20px;
}

.my_own_input:hover {
  border: solid 1px #cccccc;
}

.my_own_input:focus {
  outline-color: #3a95d6;
}
