.container {
  width: 565px;
}

.title {
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 30px;
}

.btn {
  width: 146px;
  height: 40px;
}

.btn_color {
  background-color: var(--primary-color-100) !important;
  color: #fff !important;
}

.btn_color:disabled {
  background-color: var(--primary-color-300) !important;
}

.btns {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 14px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background-color: var(--gray-900);
  padding: 20px 10px;
  border-radius: 5px;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
}

.input_type_template {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.input_type_template svg {
  fill: var(--primary-color-100);
  width: 20px;
  height: 20px;
  cursor: pointer;
  outline: none;
}

.input_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.template_type {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 5px;
  background-color: var(--gray-100);
}

.template_type_not {
  height: 35px;
  color: var(--gray-200);
}

.input_label {
  text-align: left;
}

.input_label_template {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}

.input_data,
.input_name,
.input_des {
  display: flex;
  gap: 20px;
  align-items: center;
  width: fit-content;
}

.input_data input {
  width: 310px;
  background-color: #fff;
}

.input_name input {
  width: 210px;
}

.input_des input {
  width: 565px;
}

.input_data button {
  width: fit-content;
  height: 35px;
  padding: 0 20px;
  border-radius: 5px;
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}

.delete svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-color-300);
}

.add {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media (hover: hover) and (pointer: fine) {
  .btn_color:hover {
    background-color: var(--primary-color-300) !important;
    color: #fff !important;
  }

  .btn_color:disabled:hover {
    background-color: var(--primary-color-200) !important;
    color: #fff !important;
  }

  .delete:hover svg {
    fill: var(--primary-color-400);
  }

  .input_data button:hover {
    opacity: 0.9;
  }

  .template_type:hover {
    opacity: 0.9;
  }
}
