.skeleton {
  width: calc(100% - 75px);
  display: grid;
  grid-template-columns: 1fr 40px 1fr 40px 1fr;
  height: fit-content;
}

.skeleton_level {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: column;
}

.skeleton_line {
  background-color: var(--gray-600);
  width: 1px;
  height: 300px;
}

.skeleton_body {
  width: 220px;
  height: 36px;
  position: relative;
  overflow: hidden;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.skeleton_body::after {
  content: '';
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: anima_skeleton 1500ms linear infinite normal forwards;
}

@keyframes anima_skeleton {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}
