.companies {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.add_company {
  color: var(--gray-200);
  background-color: var(--gray-600);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

@media (hover: hover) and (pointer: fine) {
  .add_company:hover {
    background-color: var(--gray-400);
  }
}

.add {
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
}

.add span {
  color: var(--gray-200);
  font-size: 1.125rem;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info svg {
  width: 15px;
  height: 15px;
  outline: none;
  fill: var(--primary-color-300);
}

.info svg path {
  fill: inherit;
}

.fluid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
}

.in_active_companies {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.label {
  color: var(--gray-200);
  font-size: 1.125rem;
}

.companies_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.line {
  background-color: var(--gray-600);
  width: 100%;
  height: 1px;
}

.company {
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
  justify-content: center;
  position: relative;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-color: #eeeeee;
}

@media (hover: hover) and (pointer: fine) {
  .company:hover {
    background-color: #e2e2e2;
  }
}
