.container {
  width: 550px;
  padding: 0 10px;
}

.title {
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 30px;
}

.btn {
  width: 146px;
  height: 40px;
}

.btn_color {
  background-color: var(--primary-color-100) !important;
  color: #fff !important;
}

.btn_color:disabled {
  background-color: var(--primary-color-300) !important;
}

.btns {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 14px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 15px;
  background-color: var(--gray-900);
  border-radius: 5px;
}

.section_title {
  font-size: 1.25rem;
  margin-bottom: -10px;
  color: var(--primary-color-100);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 35px;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: 210px;
}

.input input {
  background-color: #fff;
}

.input_span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.password {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.password span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.password span svg {
  width: 100%;
  height: 100%;
  fill: var(--gray-800);
}

.add {
  font-size: 0.875rem;
  align-items: flex-start !important;
  line-height: 0.875rem;
  background-color: var(--gray-400);
}

@media (hover: hover) and (pointer: fine) {
  .btn_color:hover {
    background-color: var(--primary-color-300) !important;
    color: #fff !important;
  }

  .btn_color:disabled:hover {
    background-color: var(--primary-color-200) !important;
    color: #fff !important;
  }

  .add:hover {
    background-color: var(--gray-100);
  }
  .password span svg:hover {
    fill: var(--gray-200);
  }
}
