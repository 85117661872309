.tags {
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  justify-content: center;
}

.return {
  color: var(--primary-color-100);
  width: fit-content;
  cursor: pointer;
}
.tags_instructions {
  margin: 15px 0;
  margin-bottom: 0;
}
.tags_company {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  padding-left: 0;
  max-height: 300px;
  overflow-y: auto;
}

.container_selected {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: var(--gray-500);
  border-radius: 10px;
}

.container_selected_span {
  width: fit-content;
  margin-top: 5px;
}

@media (hover: hover) and (pointer: fine) {
  .return:hover {
    text-decoration: underline;
  }
}
