.tickets {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tickets_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 0 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray-400);
}
