.profile_user {
  width: 85px;
  height: 85px;
  font-size: 2.25rem;
  margin-bottom: 20px;
}

.logout {
  text-decoration: underline;
  text-align: center;
  width: 100%;
  color: #fff;
  cursor: pointer;
  width: fit-content;
}

.profile div[extra-css='profile-user-settings'] {
  width: 85px;
  height: 85px;
  font-size: 2.25rem;
  margin-bottom: 20px;
}

.pswd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 11px;
}

.pswd_text {
  color: var(--primary-color-100);
  font-size: 1.125rem;
}

.pswd_dots {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.pswd_dots div {
  background-color: var(--gray-200);
  border-radius: 50%;
  width: 4px;
  height: 4px;
}

.pswd_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pswd_icon svg {
  fill: var(--primary-color-100);
  width: 17px;
  height: 17px;
}
